@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;



[data-sparta-container] .seo-overview-getstarted-module {

  @media #{$small-only} {
    margin-bottom: 30px;
    margin-top: $spacing-20	;
  }

  @media #{$medium-up} {
    margin-bottom: $spacing-40	;
    margin-top: $spacing-40	;
  }

  h3 {
    color: $color-regal-one;
    font-size: 32px;
    font-family: $cnx-light-family, $cnx-font-fallbacks;
    line-height: 36px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    text-align: center;

    @media #{$large-up} {
      font-size: 40px;
      line-height: 48px;
    }

  }
  h4 {
    color: $color-black;
    font-family: $cnx-light-family, $cnx-font-fallbacks;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 5px;
    padding: 0;
    text-align: center;

    @media #{$small-only} {
      flex-direction: column;
      margin: $spacing-12 $spacing-10 5px $spacing-10;
    }
  }

  .cta-actions {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 22px;

    @media #{$small-only} {
      flex-direction: column;
      margin-top: 0;
      margin-left: 0;
    }

    @media #{$medium-up} {
      margin-top: 25px;
    }

    .spa-btn--medium {
      white-space: nowrap;
    }

    .cta-action {
      width: auto;

      @media #{$small-only} {
        margin-top: 20px;
        height: 44px;
      }

      @media #{$medium-up} {
        margin: 0 $spacing-20 0 0;
      }

      .spa-btn {
        width: 100%;
      }
    }

    a.spa-btn {
      line-height: 1.3;
    }
  }


  }
